import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import * as R from "ramda";

import useUser from "../../hooks/useUser";
import useScheduledPatrol from "./hooks/useScheduledPatrol";
import submitPatrol from "./action/submitPatrol";

import CheckpointDashboard from "./CheckpointDashboard";
import CompletedPatrol from "./CompletedPatrol";
import SubmittingPatrol from "./SubmittingPatrol";

import { AccountUserClientType } from "../../clients/AccountUser";
import { BridgeClient } from "../../clients/BridgeApi";
import { BridgeClientContext } from "../../Context/BridgeClient";
import Loader from "../LoadingPage";

type Props = {
  apiInterface: API.Client,
  bridgeClient: BridgeClient,
  accountUserClient: AccountUserClientType,
  config: any,
};

const Patrol: React.FC<Props> = ( {
  apiInterface,
  bridgeClient,
  accountUserClient,
  config 
} ) => {
    const { uid, time } = useParams();
    const { user } = useUser( accountUserClient );
    const scheduledPatrol = useScheduledPatrol( apiInterface, uid );

    const [ pageState, setPageState ] = useState<{
      step: "patrol" | "review" | "submit", formState ?: Partial<DataModel.Patrol>
    }>( { step: "patrol" } );

    const onPatrolCompletedHandler = ( form: Partial<DataModel.Patrol> ) => {
      setPageState( { step: "review", formState: form } ); 
    }

    const onSubmitCalledHandler = async () => {
      const submitForm = pageState.formState;
      setPageState( { step: "submit" } );

      await submitPatrol( apiInterface )( {
        ...submitForm,
        patrolEndDateTime: Math.round( Date.now().valueOf() / 1000 ),
      } );

      window.close();
    }

    if ( !scheduledPatrol ) {
      return (
        <Loader />
      );
    }

    if ( pageState.step === "submit" ) {
      return (
        <SubmittingPatrol />
      );
    }
    
    if ( pageState.step === "review" ) {
      return (
        <CompletedPatrol
          checkpoints={ pageState.formState?.checkpoints as DataModel.Patrol[ "checkpoints" ] }
          onBack={ () => { 
            setPageState( { step: "patrol", formState: pageState.formState } )
          } }
          onFinish= { onSubmitCalledHandler }
        />
      );
    }

  
    const patrolForm = pageState.formState ?? {
      scheduleUid: uid || "",
      scheduledDateTime: time as string,
      patrolStartDateTime: Math.round( Date.now().valueOf() / 1000 ),
      route: R.clone( scheduledPatrol.route ),
      site: R.clone( scheduledPatrol.site ),
      operator: {
        uid: user.uid,
        email: user.username,
        name: user.displayName,
      },
      checkpoints: scheduledPatrol.checkpoints.map( ( cpoint: ( DataModel.Checkpoint & { camera: DataModel.Camera | null } ) ) => (
        {
          uid: cpoint.uid,
          name: cpoint.name,
          image: "",
          incidents: [],
          notes: "",
          timestamp: Math.round( Date.now().valueOf() / 1000 ),
          checklist: cpoint.checklistItems.map( ( item: string ) => (
            {
              description: item, checked: false
            }
          ) )
        }
      ) )
    };

    return (
      <BridgeClientContext.Provider value={bridgeClient}>
        <CheckpointDashboard
          schedulePatrol={{
            details: { checkpoints: scheduledPatrol.checkpoints },
            form: patrolForm
          }}
          startCheckpoint={ pageState.formState ? scheduledPatrol.checkpoints.length - 1 : 0 }
          operator={{ uid: user.uid, email: user.username, name: user.displayName }}
          incidentTypeList={scheduledPatrol.incidentTypes}
          config={config}
          onPatrolCompleted={onPatrolCompletedHandler}
        />
      </BridgeClientContext.Provider>
    );
};


export default Patrol;