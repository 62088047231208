import { FC } from "react";

import { Box, Button, Typography } from "@mui/material";
import CheckpointCompletedTable from "./CheckpointCompletedTable";

export interface CompletedPatrolProps {
  checkpoints: DataModel.Patrol["checkpoints"];
  onBack: () => void;
  onFinish: () => void;
}

const CompletedPatrol:FC<CompletedPatrolProps> = ({ checkpoints, onBack, onFinish }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      bgcolor="#1E1E2E"
    >
        <Box px={3} py={2.5} borderBottom="1px solid rgba(255, 255, 255, 0.2)">
          <Typography variant="h4" sx={{ fontWeight: 700 }} >
            Review Patrol
          </Typography>
        </Box>

        <Box flex="1" display="flex" px={3} py={2.5}>
          <CheckpointCompletedTable data={ checkpoints } />
        </Box>

        <Box px={3} py={2} borderTop="1px solid rgba(255, 255, 255, 0.2)">
          <Box display="flex" justifyContent="end">
            <Button variant="text" color="secondary" sx={{ mr: 2 }} onClick={ onBack }>
              <Typography color="#FFFFFF">Back</Typography>
            </Button>
            <Button variant="contained" onClick={onFinish}>Finish</Button>
          </Box>
        </Box>
    </Box>
  );
}

export default CompletedPatrol;