import getCheckpointsWithCamera from './getCheckpointsWithCamera';
import getIncidentTypes from './getIncidentTypes';

export default ( apiInstance: API.Client ) => async ( uid: string ) => {
  const schedule = await apiInstance.Entity.collection( 'schedule' ).get( uid );
  const route = await apiInstance.Entity.collection( 'route' ).get( schedule.routeRefId );
  
  const [ 
    checkpoints, site, incidentTypes 
  ] = await Promise.all( [ 
    getCheckpointsWithCamera( apiInstance )( route ), 
    apiInstance.Entity.collection( 'site' ).get( route.siteRefId ), 
    getIncidentTypes( apiInstance ), 
  ] );

  return {
    incidentTypes,
    route: { uid: route.uid, name: route.name },
    site: { uid: site.uid, name: site.name, imopsSiteId: site.imopsId },
    checkpoints,
  };
};