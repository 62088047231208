import { useEffect, useState } from "react";
import getScheduleForPatrol from "../action/getScheduleForPatrol";

export default ( apiInterface: API.Client, scheduleUid: string | undefined ) => {
  const [ scheduleDetails, setScheduleDetails ] = useState<any>(); 

  useEffect( () => {
    if ( scheduleUid ) {
      getScheduleForPatrol( apiInterface )( scheduleUid )
        .then( schedule => {
          setScheduleDetails( schedule );
        } );
    }
   
  }, [ scheduleUid ] );

  return scheduleDetails;
}