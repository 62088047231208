import * as React from "react";
import { Box, Tabs, Tab } from '@mui/material';
import * as R from "ramda";

import Checklist, { ChecklistData } from "./Checklist";
import NotesCheckpointForm from "./NotesCheckpointForm";
import ReportIncidentForm from "./ReportIncidentForm";
import submitIncident from "../../action/submitIncident";


import { BridgeClientContext } from "../../../../Context/BridgeClient";

export type ActionReportData = { notes: string, reportedIncidents: Omit<DataModel.Incident, "uid">[] };

type Prop = {
  patrolDetails: { imopsSiteId: number, operator: any },
  checkpoint: {
    details: DataModel.Checkpoint & { camera: DataModel.Camera | null },
    form: Partial<DataModel.Patrol[ "checkpoints" ][ number ]>,
    first: boolean,
    last: boolean,
  },
  incidentTypeList: DataModel.IncidentType[],
  onNextClicked: ( form: DataModel.Patrol[ "checkpoints" ][ number ] ) => void | Promise<void>,
  onBackClicked: ( form: DataModel.Patrol[ "checkpoints" ][ number ] ) => void | Promise<void>,
};

const OperationWidget: React.FC<Prop> = ( {
  patrolDetails,
  checkpoint,
  incidentTypeList,
  onNextClicked,
  onBackClicked
} ) => {
  const bridgeClient = React.useContext(BridgeClientContext);
  
  const [ actionTab, setActionTab ] = React.useState<'notes' | 'report-incident'>('notes');
  const [ checkpointForm, setCheckpointForm ] = React.useState( checkpoint.form );

  const updateCheckListData = ( newChecklistData: ChecklistData ) => {
    setCheckpointForm( oldCheckpointForm => ( {
      ...oldCheckpointForm,
      checklist: newChecklistData,
    } ) );
  }

  const onNotesChange = ( newNotes: string ) => {
    setCheckpointForm( oldCheckpointForm => ( {
      ...oldCheckpointForm,
      notes: newNotes
    } ) );
  };

  const reportIncidentHandler = async ( incidentData: any ) => {
    const incident = {
      ...incidentData,
      ...patrolDetails,
      dateTime: Math.round( Date.now().valueOf() / 1000 ),
      incidentType: R.dissocPath( [ 'priority' ], incidentData.incidentType ),
      priorityType: incidentData.incidentType?.priority,
      imopsCameraId: checkpoint.details.camera?.imopsId,
      checkpoint: {
        ...checkpoint.details,
        checklist: checkpointForm.checklist ?? [],
      }
    };

    if ( bridgeClient ) {
      await submitIncident( bridgeClient )( incident );
    }
    
    setCheckpointForm( oldCheckpointForm => {
      const reportedIncidents = oldCheckpointForm.incidents ?? [];
      return {
        ...oldCheckpointForm,
        incidents: [ ...reportedIncidents, incident ]
      }
    } );
  };

  const clickBackHandler = () => (
    onBackClicked( checkpointForm as DataModel.Patrol[ "checkpoints" ][ number ] )
  );

  const clickNextHandler = () => (
    onNextClicked( checkpointForm as DataModel.Patrol[ "checkpoints" ][ number ] )
  );

  return (
    <>
      <Box sx={{ flexGrow: 1, background: "#27293C", borderRadius: 1 }}>
        <Checklist
          checkpoint={{
            checklistForm: checkpointForm.checklist ?? [],
            first: checkpoint.first,
            last: checkpoint.last,
          }}
          onChecklistFormUpdated={updateCheckListData}
          onNextClicked={clickNextHandler}
          onBackClicked={clickBackHandler}
        />
      </Box>
      <Box sx={{ background: "#27293C", borderRadius: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={actionTab}
            onChange={(_, value) => setActionTab(value)}
          >
            <Tab label="Notes" value="notes" />
            <Tab label="Report Incident" value="report-incident" />
          </Tabs>
        </Box>
        <Box display={actionTab === 'notes' ? 'block' : 'none'}>
          <NotesCheckpointForm
            notes={ checkpointForm.notes ?? "" }
            onNotesChange={ onNotesChange }
          />
        </Box>
        <Box display={actionTab === 'report-incident' ? 'block' : 'none'}>
          <ReportIncidentForm
            incidentTypes={ incidentTypeList }
            onSubmitIncident={ reportIncidentHandler }
          />
        </Box>
      </Box>
    </>
  )
};

export default OperationWidget;