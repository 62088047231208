import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import LiveVideoPlayer from "../../../components/LiveVideoPlayer";
import ProgressBar from "../../../components/ProgressBar";
import OperationWidget from "./OperationWidget";

type PatrolForm = Partial<DataModel.Patrol>;

type Props = {
  schedulePatrol: {
    details: {
      checkpoints: ( DataModel.Checkpoint & { camera: DataModel.Camera | null } )[]
    },
    form: PatrolForm
  },
  startCheckpoint ?: number,
  operator: any,
  incidentTypeList: DataModel.IncidentType[],
  config: any,
  onPatrolCompleted: ( form: PatrolForm ) => void
};

dayjs.extend( utc );

const CheckpointDashboard: React.FC<Props> = ( {
  schedulePatrol,
  startCheckpoint,
  operator,
  incidentTypeList,
  config,
  onPatrolCompleted
} ) => {
  const [ form, setForm ] = useState<PatrolForm>( schedulePatrol.form );
  const [ checkpointPtr, setCheckpointPtr ] = useState<number>( startCheckpoint ?? 0 );

  const [ requestImageCapture, setRequestImageCapture ] = useState( {} );
  const captureImage = () => new Promise( ( resolve ) => {
    const onCapture = ( image: string | null ) => {
      resolve( image );
      setRequestImageCapture( {} );
    };
    setRequestImageCapture(
      { onCapture }
    );
  } );

  const updatePatrolForm = async ( updatedCheckpointForm: DataModel.Patrol[ "checkpoints" ][ number ] ) => {
    let image = "";
    if ( !form.checkpoints?.[ checkpointPtr ].image || form.checkpoints?.[ checkpointPtr ].image === "" ) {
      try {
        const imageResponse = await captureImage();
        image = imageResponse as string;
      } catch ( error ) {
        console.log( error );
        image = "";
      }
    } else {
      image = form.checkpoints?.[ checkpointPtr ].image ?? "";
    }

    return {
      ...form,
      checkpoints: ( form.checkpoints ?? [] ).map( ( cp, idx ) => {
        if ( idx !== checkpointPtr ) {
          return cp;
        }
        return {
          ...cp,
          ...updatedCheckpointForm,
          image,
          timestamp: Math.round( Date.now().valueOf() / 1000 ),
        };

      } )
    };
  }

  const clickBackHandler = async ( updatedCheckpointForm: DataModel.Patrol[ "checkpoints" ][ number ] ) => {
    const updatedForm = await updatePatrolForm( updatedCheckpointForm );
    setForm( updatedForm );
    if ( checkpointPtr > 0 ) {
      setCheckpointPtr( pCPPtr => pCPPtr - 1 );
    }
  }

  const clickNextHandler = async ( updatedCheckpointForm: DataModel.Patrol[ "checkpoints" ][ number ] ) => {
    const updatedForm = await updatePatrolForm( updatedCheckpointForm );

    if ( checkpointPtr < schedulePatrol.details.checkpoints.length - 1 ) {
      setForm( updatedForm );
      setCheckpointPtr( oCPPtr => oCPPtr + 1 );
    } else {
      onPatrolCompleted( updatedForm );
    }
  }

  const refCheckpoint = schedulePatrol.details.checkpoints[ checkpointPtr ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 4.5,
        background: "#1E1E2E",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', }}>
        <Typography variant="h4" sx={{ fontWeight: 700, }}>
            { schedulePatrol.form.route?.name ?? "" }
        </Typography>
      </Box>

      <Box mt={4} sx={{ p: 2, background: "#27293C", borderRadius: 1 }}>
        <ProgressBar checkpointPtr={checkpointPtr + 1} totalCheckpoint={form.checkpoints?.length ?? 0} />
      </Box>

      <Grid
        container
        item
        xs={12}
        mt={1.25}
        spacing={3}
        sx={{ minHeight: 366, flex: '1 !important' }}
      >
        <Grid item xs={12} lg={8}>
          {
            refCheckpoint.camera && (
              <LiveVideoPlayer
                id={ refCheckpoint.camera.uid }
                name={ refCheckpoint.camera.name }
                url={ refCheckpoint.camera.rtspStream }
                params={ refCheckpoint.camera.rtspParams }
                selected={ false }
                fullHeight={ true }
                config={config}
                requestImageCapture={ requestImageCapture }
              />   
            )
          }   
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={4}
          sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
        >
          <OperationWidget
            key={ schedulePatrol.details.checkpoints[ checkpointPtr ].uid }
            patrolDetails={{
              imopsSiteId: schedulePatrol.form.site?.imopsSiteId ?? 0,
              operator,
            }}
            checkpoint={{
              details: schedulePatrol.details.checkpoints[ checkpointPtr ],
              form: form.checkpoints?.[ checkpointPtr ] ?? {},
              first: checkpointPtr === 0,
              last: checkpointPtr === schedulePatrol.details.checkpoints.length - 1,
            }}
            incidentTypeList={incidentTypeList}
            onNextClicked={clickNextHandler}
            onBackClicked={clickBackHandler}
          /> 
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckpointDashboard;