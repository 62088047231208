import React from 'react';
import { Box, CircularProgress, SxProps, Theme } from '@mui/material';

const loaderSx: SxProps<Theme> = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  bgcolor: '#1E1E2E',
  color: '#FFFFFF'
};

const SubmittingPatrol = ( ) => (
  <Box component="div" sx={ loaderSx }>
    <Box component="div" sx={ { textAlign: 'center !important' } } >
      <Box mb={ 3 }>
      </Box>
      <CircularProgress />
      <p>Submitting Patrol</p>
      <p>Do not close the window. It will close when submission is completed.</p>
    </Box>
  </Box>
);

export default SubmittingPatrol;
