import React, { ChangeEvent } from "react";

import { Box } from "@mui/material";

import Textarea from "../../../../components/TextArea";

interface NotesCheckpointFormProps {
  notes: string;
  onNotesChange: (value: string) => void;
}

const NotesCheckpointForm: React.FC<NotesCheckpointFormProps> = ( { notes, onNotesChange } ) => {
  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    onNotesChange(event.target.value)
  }

  return (
    <Box p={2}>
      <Box mt={1.25}>
      <Textarea
        type="text"
        name='notes'
        minRows={4}
        value={notes}
        onChange={handleNotesChange}
      />
      </Box>
    </Box>
  );
};

export default NotesCheckpointForm;
