import React from "react";
import { Box, Button, Typography } from "@mui/material";
import BackIcon from "@mui/icons-material/ChevronLeft";
import FarwIcon from "@mui/icons-material/NavigateNext";
import Checkbox from "../../../../components/CheckboxWithLabel";

export type ChecklistData = { 
  description: DataModel.Checkpoint[ "checklistItems" ][number], 
  checked: boolean 
}[];

type Props = {
  checkpoint: {
    first: boolean,
    last: boolean,
    checklistForm: ChecklistData
  },
  onChecklistFormUpdated: ( checklistForm: ChecklistData ) => void,
  onNextClicked: () => void | Promise<void> ,
  onBackClicked: () => void | Promise<void>,
}

const CheckList: React.FC<Props> = ({
  checkpoint, onChecklistFormUpdated, onNextClicked, onBackClicked
}) => {
    const handleListChange = (idx: number, checked: boolean) => {
      onChecklistFormUpdated( checkpoint.checklistForm.map( ( initItem, iidx ) => (
        iidx === idx
          ? {
            ...initItem,
            checked,
          }
          : initItem
      ) ) );
    };

    const nextClickedHandler = () => {
      onNextClicked();
    };

    const backClickedHandler = () => {
      onBackClicked();
    }

    const buttonColour = checkpoint.last ? "#DD5151" : "#18AB79";
    return (
      <Box height="100%" minHeight={350} sx={{ display: "flex", flexDirection: "column" }}>
        <Box flexGrow={1} flex="1" p={2.5}>
          <Typography
            sx={{
              fontSize: 18,
              borderBottom: `1px solid rgba(255, 255, 255, 0.2)`,
              pb: 0.5,
            }}
          >
            Checklist
          </Typography>
          <Box sx={{ overflowY: "auto" }}>
            {checkpoint.checklistForm.map((item: ChecklistData[number], ind: number) => (
              <Checkbox
                ind={ind}
                key={ind}
                onChange={handleListChange}
                label={item.description}
                checked={item.checked}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            py: 2,
            px: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!checkpoint.first && (
            <Button
              variant="contained"
              startIcon={<BackIcon />}
              sx={{
                mr: 0.75,
                background: "#D07F33",
                "&:hover": { background: "#D07F33" },
                "& .MuiButton-startIcon": { mr: 0 },
                px: 2.5,
                py: 1
              }}
              onClick={backClickedHandler}
            >
              Back
            </Button>
          )}
          <Button
            variant="contained"
            endIcon={!checkpoint.last && <FarwIcon />}
            onClick={nextClickedHandler}
            sx={{
              background: buttonColour,
              "& .MuiButton-endIcon": { ml: 0 },
              "&:hover": {
                background: buttonColour
              },
              px: 2.5,
              py: 1
            }}
          >
            {
              checkpoint.last
                ?  (
                  <>Review</>
                )
                : (
                  <>Next</>
                )
            }
          </Button>
        </Box>
      </Box>
    );
};

export default CheckList;
