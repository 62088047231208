import React from "react";
import { Box, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as R from 'ramda';

import CustomBtn from "../../../../components/Button";
import Textarea from "../../../../components/TextArea";
import CustomFormSelect from "../../../../components/CustomFormSelect";

const ReportIncidentForm: React.FC<any> = ( { incidentTypes, onSubmitIncident } ) => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({ defaultValues: { comments: '', incidentType: '' } });

    const incidentTypeMap = R.pipe(
      R.map( ( iT: any ) => ( [ iT.key, iT ] as [ string, any ] ) ),
      R.fromPairs, 
    )( incidentTypes );
    
    const handleSubmitData = async ( data: any ) => {        
        const modifiedData = {
          ...data,
          incidentType: incidentTypeMap[ data.incidentType ],
        };
        setValue( 'comments', '' );
        setValue( 'incidentType', '' );
        onSubmitIncident( modifiedData );
    };

    return (
      <form onSubmit={handleSubmit(handleSubmitData)} noValidate>
        <Box>
          <Box p={2}>
            <Box mt={1.25}>
              <Controller
                control={control}
                name="comments"
                defaultValue=""
                render={({ field: { ref, onChange, ...field } }) => (
                  <Textarea
                    {...field}
                    name={'comments'}
                    minRows={4}
                    onChange={onChange}
                    helperText={errors?.notes?.message}
                    error={!!errors?.notes}
                    type="text"
                    datatestid={'notes-form-description'}
                  />
                )}
              />
            </Box>
              <Box mt={1.25}>
                <CustomFormSelect
                  required
                  label="Incident Type *"
                  control={control}
                  name="incidentType"
                  rules={{ required: true }}
                >
                  {
                    incidentTypes?.map( ( item: any ) => (
                      <MenuItem  value={item.key} key={item.key}>{item.displayName}</MenuItem>
                    ) )
                  }
                </CustomFormSelect>
                { errors?.incidentType && <small style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    display: 'block',
                    padding: '4px 0px 0px 22px',

                }}>
                    Please select incident type
                </small> }
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                p: 2,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
            <CustomBtn
              btnType={'contained'}
              btnText="Submit"
              sx={{ background: "#0085B4", "&:hover": { background: "#0085B4" }}}
            />
          </Box>
        </Box>
      </form>
    );
};

export default ReportIncidentForm;